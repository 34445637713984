import React, { createContext,useContext, useEffect, useRef, useState } from 'react'

var authContext = createContext();
let prodURL = "https://wai.docsun.health/api/clientron";
export function useAuth() {
    const login = async (uname,pass)=>{
        let data = {
            'email':uname,
            'password':pass
        };
        let response = await fetch(`${prodURL}/auth/login`,{
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify(data)
        })
        response = await response.json();
        if (response.success){
            sessionStorage.setItem("status",true)
            sessionStorage.setItem("licence",response.licencekey);
            sessionStorage.setItem("token",response.accessToken);
            return {
                status:true,message:response.message,accessToken:response.result.accessToken,licencekey:response.result.licencekey,id:response.result.id
            }
        }else{
            sessionStorage.setItem("status",false)
            return {
                status:false,
                message:response.message,
                accessToken:'',
                licencekey:'',
                id:''
            }
        }
    }
  return {
    login,
    };
}
export function AuthProvider({children}){
    var auth = useAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>
}
export default function AuthConsumer(){
    return useContext(authContext);
}